.loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.fab {
    position: fixed !important;
    bottom: 2em;
    right: 2em;
}

.companyFilterContainer {
    box-sizing: border-box;
    width: 96% !important;
    padding: 1em;
}

.companyFilterInputCell {
    flex: 1 1 0;
    box-sizing: content-box;
}

.companyFilterInput {
    width: 100%;
}

.companiesGrid {
    width: 96% !important;
}

.company {
    padding: 0.6em 0;
    user-select: none;
    cursor: pointer;
}

.companyData {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
