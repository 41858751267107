.CompanyContainer {
    padding: 2em;
    margin: 2em auto;
}

.loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.CompanyForm {
    padding-top: 2em;
    margin: 0 auto;
    max-width: 600px;
}

.CompanyError {
    margin: 1em 0 1em;
}

.featuresList {
    text-transform: capitalize;
}

.buttonsContainer {
    display: flex;
    flex-direction: row;
    padding: 1em 0;
    justify-content: space-between;
}
