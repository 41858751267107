.SignInContainer {
    padding: 2em;
    width: 510px;
}

.SignInContainer, .loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.SignInForm {
    margin: 0 auto;
    max-width: 600px;
}

.SignInError {
    margin: 1em 0 1em;
}
